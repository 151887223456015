.height-80 {
  height: 80px;
}
.w-col-activity-name {
  width: 18%;
}
.w-col-activity-address {
  width: 14%;
}
.w-col-activity-city {
  width: 15%;
}
.w-col-activity-phone {
  width: 13%;
}
.w-col-activity-email {
  width: 20%;
}
.w-col-activity-website {
  width: 15%;
}
.w-col-activity-visits {
  width: 5%;
}