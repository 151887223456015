.height-80 {
  height: 80px;
}
.w-col-title {
  width: 40%;
}
.w-col-city {
  width: 10%;
}
.w-col-postal-code {
  width: 12%;
}
.w-col-price {
  width: 13%;
}
.w-col-rent-price {
  width: 10%;
}
.w-col-living-surface {
  width: 15%;
}