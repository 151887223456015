.height-table {
  height: 78vh;
}
.height-80 {
  height: 80px;
}
.width-col-name {
  width: 22%;
}
.width-col-city {
  width: 18%;
}
.width-col-phone {
  width: 12%;
}
.width-col-profession {
  width: 16%;
}