@media (min-width: 768px) { 
  .margin-left {
    margin-left: 20%;
  }
}
@media all and (min-width: 1024px) { 
  .margin-left {
    margin-left: 20%;
  }
}
@media all and (min-width: 1280px) { 
  .margin-left {
    margin-left: 20%;
  }
}
@media all and (min-width: 1536px) { 
  .margin-left {
    margin-left: 16%;
  }
}