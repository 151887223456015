.w-col-name {
  width: 20%;
}
.w-col-address {
  width: 20%;
}
.w-col-city {
  width: 15%;
}
.w-col-phone {
  width: 15%;
}
.w-col-type {
  width: 10%;
}
.w-col-website {
  width: 15%;
}
.w-col-actions {
  width: 5%;
}