@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

body, * {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
	width: 2px;
	background-color: #F5F5F5;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
	border-radius: 10px;
	background-color: #F5F5F5;
}
 
body::-webkit-scrollbar-thumb {
	border-radius: 5px;
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
	background-color: #555;
	outline: 1px solid slategrey;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
}
.input-group-prepend {
	width: 105px !important;
}
.input-group-text {
	width: 100% !important;
}