.height-table {
  height: 78vh;
}
.height-80 {
  height: 80px;
}
.width-col-name {
  width: 15%;
}
.width-col-description {
  width: 50%;
}
.width-col-price {
  width: 10%;
}
.width-col-profession {
  width: 25%;
}