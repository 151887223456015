.bg-sidemenu {
    box-shadow: 0 10px 30px -12px rgb(0 0 0 / 42%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
    position: fixed;
    z-index: 999;
    height: 100%;
}
.bg-sidemenu::before {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(../../assets/background.jpg);
    filter: brightness(0.2) grayscale(50%);
    -webkit-filter: brightness(0.2) grayscale(50%);
}