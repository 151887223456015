.height-table {
  height: 78vh;
}
.height-80 {
  height: 80px;
}
.width-col-username {
  width: 20%;
}
.width-col-name {
  width: 18%;
}
.width-col-surname {
  width: 18%;
}
.width-col-mail {
  width: 26%;
}
.width-col-active {
  width: 8%;
}
.width-col-superuser {
  width: 10%;
}